import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import {
  isUserLoggedIn,
  getUserData,
  getUserRole,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";
import config from "@/store/config";
import i18n from "@/libs/i18n";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: "/", redirect: { name: "login" } },
    {
      path: "/",
      meta: {
        needLogin: true,
      },
      beforeEnter: (to, from, next) => {
        store.dispatch("auth/checkToken");
        next();
      },
      component: () => import("@/layouts/full/LayoutFull.vue"),
    },
    {
      path: "/profile",
      meta: {
        needLogin: true,
        roleAllow: ["super_admin"],
      },
      name: "profile",
      component: () => import("@/views/account/AccountPage.vue"),
    },
    {
      path: "/carpetas/:hash",
      name: "carpetas",
      meta: {
        layout: "full",
      },
      component: () => import("@/views/folders/Access.vue"),
    },
    {
      path: "/folders",
      meta: {
        needLogin: true,
        roleAllow: ["super_admin"],
      },
      name: "folders",
      component: () => import("@/views/folders/List.vue"),
    },
    {
      path: "/folders/view/public/:id",
      name: "viewPublicFolder",
      meta: {
        layout: "full",
      },
      component: () => import("@/views/folders/ViewPublic.vue"),
    },
    {
      path: "/folders/view/:id",
      meta: {
        needLogin: true,
        roleAllow: ["super_admin"],
      },
      name: "viewFolder",
      component: () => import("@/views/folders/View.vue"),
    },
    {
      path: "/users",
      meta: {
        needLogin: true,
        roleAllow: ["super_admin"],
      },
      name: "users",
      component: () => import("@/views/users/List.vue"),
    },
    {
      path: "/users/edit/:id",
      meta: {
        needLogin: true,
        roleAllow: ["super_admin"],
      },
      name: "editUser",
      component: () => import("@/views/users/Edit.vue"),
    },
    {
      path: "/users/view/:id",
      meta: {
        needLogin: true,
        roleAllow: ["super_admin"],
      },
      name: "viewUser",
      component: () => import("@/views/users/View.vue"),
    },
    {
      path: "/users/create",
      meta: {
        needLogin: true,
        roleAllow: ["super_admin"],
      },
      name: "createUser",
      component: () => import("@/views/users/Create.vue"),
    },

    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
        redirectIfLoggedIn: true,
      },
    },
    {
      path: "/reset-password/:token/:email",
      name: "reset-password",
      component: () => import("@/views/users/ResetPassword.vue"),
      meta: {
        layout: "full",
        redirectIfLoggedIn: true,
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-401",
      name: "error-401",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();

  if (to.meta.needLogin) {
    if (!isLoggedIn) {
      return next({ name: "login" });
    }
  }

  const lang = to.query?.lang;
  if (lang) {
    i18n.locale = lang;
    store.commit("languages/setCurrentLanguage", lang);
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn === true && isLoggedIn) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData));
  }
  if (to.meta.roleAllow) {
    const role = getUserRole();
    if (!to.meta.roleAllow.includes(role)) {
      next({ name: "error-401" });
    }
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to) => {
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;

import archivesApi from "@/api/archives-api";
import DownloadService from "@/shared/services/download-service";

const actions = {
  async save({ commit }, archive) {
    try {
      await archivesApi.save(archive);
    } catch (err) {
      commit(
        "notifications",
        { title: "Error al subir archivos.", variant: "danger" },
        { root: true }
      );
    } finally {
      commit("setShowNotifications", true, { root: true });
    }
  },
  async downloadArchive({ commit }, { folder_id, archive, action }) {
    try {
      const response = await archivesApi.download(folder_id, archive.id );
      const filename = archive.name || DownloadService.getFilenameFromResponse(response)

      if(action === 'download') {
        DownloadService.resolveAndDownloadBlob(new Blob([response.data]), filename)
      }
      if(action === 'view') {
        const blob = new Blob([response.data], { type: 'application/pdf' });

        const url = URL.createObjectURL(blob);

        window.open(url, '_blank');
      }
    } catch (err) {
      commit(
        "notifications",
        { title: "Error al descargar el archivo.", variant: "danger" },
        { root: true }
      );
    } finally {
      commit("setShowNotifications", true, { root: true });
    }
  },
  async downloadPublicArchive({ commit }, { folder_id, archive, action }) {
    try {
      const response = await archivesApi.downloadPublic(folder_id, archive.id);
      const filename = archive.name || DownloadService.getFilenameFromResponse(response)
      if(action === 'download') {
        DownloadService.resolveAndDownloadBlob(new Blob([response.data]), filename)
      }
      if(action === 'view') {
        const blob = new Blob([response.data], { type: 'application/pdf' });

        const url = URL.createObjectURL(blob);

        window.open(url, '_blank');
       }
    } catch (err) {
      commit(
        "notifications",
        { title: "Error al descargar el archivo.", variant: "danger" },
        { root: true }
      );
    } finally {
      commit("setShowNotifications", true, { root: true });
    }
  },
  async downloadArchivesZip({ commit }, { name, folder_id }) {
    try {
      const response = await archivesApi.downloadAll(folder_id);
      DownloadService.resolveAndDownloadBlob(response.data, name, "zip");
    } catch (err) {
      commit(
        "notifications",
        { title: "Error al descargar los archivos.", variant: "danger" },
        { root: true }
      );
    } finally {
      commit("setShowNotifications", true, { root: true });
    }
  },
  async downloadPublicArchivesZip({ commit }, { name, folder_id }) {
    try {
      const response = await archivesApi.downloadPublicAll(folder_id);
      DownloadService.resolveAndDownloadBlob(response.data, name, "zip");
    } catch (err) {
      commit(
        "notifications",
        { title: "Error al descargar los archivos.", variant: "danger" },
        { root: true }
      );
    } finally {
      commit("setShowNotifications", true, { root: true });
    }
  },
};

export default {
  namespaced: true,
  actions,
  // state,
  // mutations,
  // getters,
};

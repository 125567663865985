import axios from "axios";
import { config } from "../shared/app.config";

const RESOURCE_NAME = "/archives";

export default {
  save(archive) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, archive);
  },
  download(folder_id, archive_id) {
    return axios({
      url: `${config.webApiBase + RESOURCE_NAME}/download/${folder_id}`,
      params: { id: archive_id },
      method: "GET",
      responseType: "blob",
    });
  },
  downloadPublic(folder_id, archive_id) {
    return axios({
      url: `${config.webApiBase + RESOURCE_NAME}/download/public/${folder_id}`,
      params: { id: archive_id },
      method: "GET",
      responseType: "blob",
    });
  },
  downloadAll(folder_id) {
    return axios({
      url: `${config.webApiBase + RESOURCE_NAME}/download-zip/${folder_id}`,
      data: {},
      method: "GET",
      responseType: "blob",
    });
  },
  downloadPublicAll(folder_id) {
    return axios({
      url: `${
        config.webApiBase + RESOURCE_NAME
      }/download-zip/public/${folder_id}`,
      data: {},
      method: "GET",
      responseType: "blob",
    });
  },
};

import router from '@/router'
import i18n from '@/libs/i18n'
import usersApi from '../../api/users-api'

const state = {
  user: {},
  items: [],
  totalItems: 0,
  selectUsers: [],
  checkJourney: [],
  roles: [],
}

const mutations = {
  setUser(state, val) {
    state.user = val
  },
  setCheckJourney(state, val) {
    state.checkJourney = val
  },
  setItems(state, val) {
    state.items = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
  setSelectUsers(state, val) {
    state.selectUsers = val
  },
  setRoles(state, val) {
    state.roles = val
  },
}

const actions = {
  async selectUsers({ commit }, { roles = '' }) {
    await usersApi.list(1, 999999, '', roles)
      .then(
        response => {
          commit('setSelectUsers', response.data.data)
        },
        () => {
          commit('notifications', { title: 'Error en la búsqueda de usuarios.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  edit({ commit }, { id, user }) {
    commit('app/loading', true, { root: true })
    usersApi.edit(id, user)
      .then(
        () => {
          commit('notifications', { title: 'Usuario actualizado con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar usuario.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getUser({ commit }, id) {
    commit('app/loading', true, { root: true })
    await usersApi.getUser(id)
      .then(
        response => {
          commit('setUser', response.data.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener el usuario.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  getListUsers({ commit }, {
    page, per_page, search = '', roles = [],
  }) {
    commit('app/loading', true, { root: true })
    usersApi.list(page, per_page, search, roles)
      .then(
        response => {
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener el listado de usuarios.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    usersApi.delete(id)
      .then(
        () => {
          commit('notifications', { title: 'Usuario eliminado con éxito.', variant: 'success' }, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al eliminar el usuario.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async create({ commit }, { user }) {
    commit('app/loading', true, { root: true })
    try {
      await usersApi.create(user)
      commit('notifications', { title: i18n.t('register.success'), variant: 'success' }, { root: true })
      await router.push({ name: 'login' })
    } catch (error) {
      if (error.response.data.error) {
        commit('notifications', { title: i18n.t(`register.failed.${error.response.data.error}`), variant: 'danger' }, { root: true })
      } else if (error.response.data.email && error.response.data.email[0]) {
        commit('notifications', { title: error.response.data.email[0], variant: 'danger' }, { root: true })
      }
    } finally {
      commit('app/loading', false, { root: true })
      commit('setShowNotifications', true, { root: true })
    }
  },
  createnew({ commit }, { user, route = 'createUser' }) {
    commit('app/loading', true, { root: true })
    usersApi.createnew(user)
      .then(
        response => {
          commit('notifications', { title: 'Usuario creado con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          if (route === 'createUser') {
            router.push({ name: 'users' })
          } else {
            router.push({ name: 'internals' })
          }
          // router.push({ name: 'editUser', params: { id: response.data.data.id } })
          commit('app/loading', false, { root: true })
        },
        () => {
          console.log('datos', user)
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al crear usuario.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  resetPassword({ commit }, { id, oldPassword, newPassword }) {
    commit('app/loading', true, { root: true })
    usersApi.resetPassword(id, oldPassword, newPassword).then(
      () => {
        commit('notifications', { title: 'Usuario actualizado con éxito.', variant: 'success' }, { root: true })
        commit('setShowNotifications', true, { root: true })
        commit('app/loading', false, { root: true })
      },
      error => {
        commit('app/loading', false, { root: true })
        commit('setShowNotifications', true, { root: true })
        if (error.response.data.error && error.response.data.error === 'Old Password incorrect') {
          commit('notifications', { title: 'Contraseña actual incorrecta', variant: 'danger' }, { root: true })
        } else {
          commit(
            'notifications',
            { title: i18n.t('ErrorUpdate', { nombre: i18n.t('Usuario') }), variant: 'danger' },
            { root: true },
          )
        }
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
}

const getters = {
  getUser: state => state.user,
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
  getSelectUsers: state => state.selectUsers,
  getRoles: state => state.roles,
  getCheckJourney: state => state.checkJourney,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

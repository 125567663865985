import logsApi from '@/api/logs-api'
import DownloadService from "@/shared/services/download-service";

const actions = {
  async downloadLogs({ commit }) {
    commit("app/loading", true, { root: true });
    logsApi.downloadExcel()
      .then((response) => {
        DownloadService.resolveAndDownloadBlob(response.data, 'Logs', 'xlsx')
      })
      .catch(() => {
        commit(
          "notifications",
          { title: "Error al descargar el archivo.", variant: "danger" },
          { root: true }
        );
      })
      .finally(() => {
        commit("app/loading", false, { root: true });
      })
  }
}

export default {
  namespaced: true,
  actions
}
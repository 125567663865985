import axios from 'axios'
import { config } from '@/shared/app.config'

const RESOURCE_NAME = '/logs'

export default {
  downloadExcel() {
    return axios({
      url: `${config.webApiBase + RESOURCE_NAME}/excel`,
      method: "GET",
      responseType: "blob",
    })
  },
}
import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import notifications from './notifications'
import auth from './auth'
import users from './users'
import config from './config'
import alerts from './alerts'
import languages from './languages'
import logs from './logs'
import folders from './folders'
import archives from './archives'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    notifications,
    auth,
    users,
    languages,
    alerts,
    logs,
    config,
    folders,
    archives
  },
  strict: process.env.DEV,
})

import foldersApi from "@/api/folders-api";
// import i18n from "@/libs/i18n";
import router from "../../router";
import DownloadService from "@/shared/services/download-service";

const state = {
  items: [],
  totalItems: 0,
  token: {},
};

const mutations = {
  setItems(state, val) {
    state.items = val;
  },
  setTotalItems(state, val) {
    state.totalItems = val;
  },
  accessSuccess(state, responseData) {
    state.token = {
      token: responseData.token,
      hash: responseData.hash,
      expires_in: new Date(
        new Date().getTime() + responseData.expires_in * 1000
      ).getTime(),
    };

    localStorage.setItem("token", JSON.stringify(responseData));
  },
};

const actions = {
  getListFolders({ commit }, { page, per_page, search, loading }) {
    if(loading) {
      commit("app/loading", true, { root: true });
    }
    foldersApi.list(page, per_page, search).then(
      (response) => {
        commit("setItems", response.data.data);
        commit("setTotalItems", response.data.meta.total[1]);
        commit("app/loading", false, { root: true });
      },
      () => {
        commit("app/loading", false, { root: true });
        commit(
          "notifications",
          {
            title: "Error al obtener el listado de carpetas.",
            variant: "danger",
          },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  async delete({ commit }, id) {
    try {
      await foldersApi.delete(id);
      commit(
        "notifications",
        { title: "Carpeta eliminada con éxito.", variant: "success" },
        { root: true }
      );
    } catch {
      commit(
        "notifications",
        { title: "Error al eliminar la carpeta.", variant: "danger" },
        { root: true }
      );
    } finally {
      commit("setShowNotifications", true, { root: true });
    }
  },
  async create({ commit }, folder) {
    try {
      await foldersApi.create(folder);
      commit(
        "notifications",
        { title: "Carpeta creada con éxito.", variant: "success" },
        { root: true }
      );
    } catch (err) {
      commit(
        "notifications",
        { title: "Error al crear la carpeta.", variant: "danger" },
        { root: true }
      );
    } finally {
      commit("setShowNotifications", true, { root: true });
    }
  },
  async createStructure({ commit }, { folder_id, structure }) {
    try {
      await foldersApi.createStructure(folder_id, structure);
      // commit(
      //   "notifications",
      //   { title: "Carpeta creada con éxito.", variant: "success" },
      //   { root: true }
      // );
    } catch (err) {
      commit(
        "notifications",
        { title: "Error al crear la carpeta.", variant: "danger" },
        { root: true }
      );
    } finally {
      commit("setShowNotifications", true, { root: true });
    }
  },
  async edit({ commit }, { id, folder }) {
    try {
      await foldersApi.edit(id, folder);
      commit(
        "notifications",
        { title: "Carpeta actualizada con éxito.", variant: "success" },
        { root: true }
      );
    } catch (err) {
      commit(
        "notifications",
        { title: "Error al actualizar la carpeta.", variant: "danger" },
        { root: true }
      );
    } finally {
      commit("setShowNotifications", true, { root: true });
    }
  },
  async share({ commit }, { hash, ip, type }) {
    try {
      await foldersApi.share(hash, ip, type);
    } catch (err) {
      commit(
        "notifications",
        { title: "Error al compartir la carpeta.", variant: "danger" },
        { root: true }
      );
    } finally {
      commit("setShowNotifications", true, { root: true });
    }
  },
  async generateQr({ commit }, { id, password }) {
    try {
      const response = await foldersApi.getQr(id, password);
      const url = window.URL.createObjectURL(new Blob([response.data]));

      const a = document.createElement("a");
      a.href = url;
      a.download = "qr_code.png";
      document.body.appendChild(a);
      a.click();

      // Limpia el enlace del DOM
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      commit(
        "notifications",
        { title: "QR Generado con éxito.", variant: "success" },
        { root: true }
      );
    } catch (err) {
      commit(
        "notifications",
        { title: "Error al generar QR.", variant: "danger" },
        { root: true }
      );
    } finally {
      commit("setShowNotifications", true, { root: true });
    }
  },
  async getFolderName({ commit }, { hash, ip }) {
    try {
      return await foldersApi.getName(hash, ip);
    } catch (err) {
      commit(
        "notifications",
        {
          title: "Error al obtener el nombre de la carpeta.",
          variant: "danger",
        },
        { root: true }
      );
    } finally {
      commit("setShowNotifications", true, { root: true });
      commit("app/loading", false, { root: true });
    }
  },
  async getFolder({ commit }, id) {
    commit("app/loading", true, { root: true });
    try {
      return await foldersApi.get(id);
    } catch (err) {
      commit(
        "notifications",
        { title: "Error al obtener la carpeta.", variant: "danger" },
        { root: true }
      );
    } finally {
      commit("setShowNotifications", true, { root: true });
      commit("app/loading", false, { root: true });
    }
  },
  async accessFolder({ commit }, { hash, password, ip_address }) {
    commit("app/loading", true, { root: true });
    try {
      const response = await foldersApi.accessFolder(
        hash,
        password,
        ip_address
      );
      commit("accessSuccess", { hash, ...response.data });
      await router.push({
        name: "viewPublicFolder",
        params: { id: response.data.id },
      });
    } catch (err) {
      commit("app/loading", false, { root: true });
      commit(
        "notifications",
        { title: "Error al acceder a la carpeta.", variant: "danger" },
        { root: true }
      );
    } finally {
      commit("setShowNotifications", true, { root: true });
    }
  },
  async removeAccessFolder() {
    localStorage.removeItem("token");
  },
  async getPublicFolder({ dispatch, commit }, folder_id) {
    try {
      commit("app/loading", false, { root: true });
      return await foldersApi.getPublicFolder(folder_id);
    } catch (error) {
      if (error.response?.status === 401 || 403) {
        const hash = JSON.parse(localStorage.getItem("token")).hash;
        await router.push({ name: "carpetas", params: { hash } });
        commit(
          "notifications",
          {
            title: "El acceso no es válido. Ingresa la contraseña nuevamente.",
            variant: "danger",
          },
          { root: true }
        );
        dispatch("removeAccessFolder");
      } else {
        commit(
          "notifications",
          { title: "Error al obtener la carpeta.", variant: "danger" },
          { root: true }
        );
      }
    } finally {
      commit("setShowNotifications", true, { root: true });
    }
  },
};

const getters = {
  getItems: (state) => state.items,
  getTotalItems: (state) => state.totalItems,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

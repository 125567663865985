import axios from "axios";
import { config } from "../shared/app.config";

const RESOURCE_NAME = "/folders";

export default {
  edit(id, folder) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, folder);
  },
  create(folder) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, folder);
  },
  createStructure(folder_id, structure) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/create-structure/${folder_id}`, structure);
  },
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`);
  },
  get(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`);
  },
  getQr(id, password) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/qr`, 
      { id, password },
      { responseType: 'blob' }
    );
  },
  list(page, per_page, search) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      page,
      per_page,
      search,
    });
  },
  // PUBLIC
  share(hash, ip, type) {
    return axios.get(`${config.webApiBase}/public${RESOURCE_NAME}/share`, {
       params: { hash, ip, type }
    });
  },
  getName(hash, ip) {
    return axios.get(`${config.webApiBase}/public${RESOURCE_NAME}/getname`, { params: { hash, ip }});
  },
  accessFolder(hash, password, ip_address) {
    return axios.post(`${config.webApiBase}/public${RESOURCE_NAME}/access`, { hash, password, ip_address });
  },
  getPublicFolder(folder_id) {
    return axios.get(`${config.webApiBase}${RESOURCE_NAME}/show/public/${folder_id}`);
  }
};

import i18n from '@/libs/i18n'
import authApi from '../../api/auth-api'
import router from '../../router'

const state = {
  loginFailed: false,
  user: {},
  token: {},
  userMe: {},
}

const mutations = {
  recoverUser() {
    const recoveredUser = JSON.parse(localStorage.getItem('userData'))
    if (!recoveredUser) {
      state.status = null
      state.user = null
    } else {
      state.status = { loggedIn: true }
      state.user = recoveredUser
    }
  },
  meSuccess(_, val) {
    state.userMe = val
  },
  header(_, val) {
    state.header = val
  },
  logout() {
    state.status = {}
    state.token = {}
    state.user = null
  },
  refreshTokenSuccess(_, responseData) {
    const recoveredToken = JSON.parse(localStorage.getItem('token'))
    recoveredToken.token = responseData.access_token
    recoveredToken.expires_in = new Date(new Date().getTime() + responseData.expires_in * 1000).getTime()
    localStorage.setItem('token', JSON.stringify(recoveredToken))
  },
  loginSuccess(_, responseData) {
    state.status = { loggedIn: true }
    state.token = {
      token: responseData.access_token,
      token_type: responseData.token_type,
      expires_in: new Date(new Date().getTime() + responseData.expires_in * 1000).getTime(),
    }
    state.user = responseData.user_data
    state.loginFailed = false

    localStorage.setItem('userData', JSON.stringify(state.user))
    localStorage.setItem('token', JSON.stringify(state.token))
  },
  loginFailure() {
    state.status = {}
    state.token = {}
    state.user = null
    state.loginFailed = true
  },
}

const actions = {
  async login({ commit }, { email, password }) {
    commit('app/loading', true, { root: true })
    try {
      const response = await authApi.login(email, password)
      const loginData = response.data

      let route = 'folders'

      commit('loginSuccess', loginData)
      commit('notifications', { title: i18n.t('welcome') }, { root: true })
      await router.push({ name: route })
    } catch (error) {
      console.error(error)
      commit('loginFailure', error)
      commit('notifications', { title: i18n.t(`login.failedAttempt.${error.response.data.error}`), variant: 'danger' }, { root: true })
    } finally {
      commit('app/loading', false, { root: true })
      commit('setShowNotifications', true, { root: true })
    }
  },
  async recoverPassword({ commit }, { email }) {
    commit('app/loading', true, { root: true })
    try {
      await authApi.recoverPassword(email)
      commit('notifications', { title: i18n.t('login.recoverEmailSent'), variant: 'success' }, { root: true })
    } catch (error) {
      console.error(error)
      commit('notifications', { title: i18n.t('login.recoverEmailFailed'), variant: 'danger' }, { root: true })
    } finally {
      commit('app/loading', false, { root: true })
      commit('setShowNotifications', true, { root: true })
    }
  },
  resetPassword({ commit }, {
    email, password, password_confirmation, token,
  }) {
    commit('app/loading', true, { root: true })
    authApi.resetPassword(email, password, password_confirmation, token)
      .then(
        () => {
          commit('notifications', { title: i18n.t('Contraseña actualizada correctamente'), variant: 'success' }, { root: true })
          router.push({ name: 'login' }, () => {
            commit('app/loading', false, { root: true })
            commit('setShowNotifications', true, { root: true })
          })
        },
        () => {
          commit('notifications', { title: i18n.t('No ha sido posible actualizar su contraseña'), variant: 'danger' }, { root: true })
          commit('app/loading', false, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  me({ commit }) {
    authApi.me()
      .then(
        response => {
          commit('meSuccess', response.data)
        },
        () => {
          commit('notifications', { title: 'No ha sido posible recurar la información.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  logout({ commit }) {
    commit('app/loading', true, { root: true })
    commit('languages/setCurrentLanguage', null, { root: true })
    commit('clients/removeCurrentClient', '', { root: true })
    localStorage.removeItem('userData')
    localStorage.removeItem('token')
    commit('logout')
    commit('notifications', { title: i18n.t('login.successfullyLogout'), variant: 'success' }, { root: true })
    router.push({ name: 'login' }, () => {
      commit('languages/setCurrentLanguage', null, { root: true })
      commit('app/loading', false, { root: true })
      commit('setShowNotifications', true, { root: true })
      location.reload()
    })
  },
  checkToken({ commit, dispatch }) {
    const token = JSON.parse(localStorage.getItem('token'))
    const current = new Date().getTime()
    if (!token || current >= token.expires_in) {
      commit('notifications', { title: 'Sesión expirada, vuelva a iniciar sesión.', variant: 'danger' }, { root: true })
      dispatch('logout')
      router.push({ name: 'login' }, () => {
        commit('app/loading', false, { root: true })
        commit('setShowNotifications', true, { root: true })
      })
    } else if ((token.expires_in - current) < 1800000) {
      dispatch('refreshToken')
    }
  },
  refreshToken({ commit }) {
    authApi.refreshToken().then(response => {
      commit('refreshTokenSuccess', response.data)
    })
  },
}

const getters = {
  getUser: () => state.user,
  getRole: () => state.user?.roles[0].name || '',
  getAvatar: () => state.user?.avatar || process.env.VUE_APP_IMAGE_PLACEHOLDER,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
